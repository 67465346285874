/* src/js/ltce/src/components/ChatView.css */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-box {
  flex: 1;
  overflow-y: auto;
  padding: 20px 30px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  line-height: 1.5;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
}

/* Styling for user messages */
.message.user {
  flex-direction: row-reverse; /* Align user messages to the right */
}

.message.user .icon {
  margin-right: 0; /* Remove right margin for user */
}

.message.bot .icon {
  margin-left: 0; /* Remove left margin for bot */
}

/* Icon Styling */
.icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.icon svg {
  width: 100%;
  height: 100%;
  fill: #007bff; /* Adjust icon color if needed */
}

/* Message Content Styling */
.message-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

/* Bot Message Styling (No Background) */
.bot-message {
  /* Optionally, add styles for bot messages if needed */
}

/* Markdown Content Styling */
.markdown-content {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

.markdown-content p {
  margin: 0.5em 0;
}

.markdown-content code {
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
}

.markdown-content pre {
  background-color: #f5f5f5;
  padding: 1em;
  border-radius: 4px;
  overflow-x: auto;
}

.markdown-content ul,
.markdown-content ol {
  margin: 0.5em 0;
  padding-left: 1.5em;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  width: 40%;
  background-color: #e0e0e0; /* Matches user bubble background */
  border-radius: 20px;
  padding: 4px 12px;
  gap: 4px;
}

.chat-input {
  flex: 1;
  resize: none;
  padding: 4px;
  border: none;
  font-size: 14px;
  line-height: 1.4;
  max-height: 100px;
  overflow-y: auto;
  background: transparent;
  color: #000; /* Match user bubble text color */
  vertical-align: middle;
  min-height: 24px;
  display: flex;
  align-items: center;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Style the placeholder text */
.chat-input::placeholder {
  line-height: 24px; /* Matches min-height */
  vertical-align: middle;
}

.chat-input:focus {
  outline: none;
}

.send-button {
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;
}

.send-button:hover {
  transform: scale(1.1);
}

/* Define the arrow icon using CSS */
.send-arrow {
  width: 18px;
  height: 18px;
  border: solid #007bff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
}

.logout-button {
  padding: 5px 10px;
  font-size: 14px;
  border: none;
  background-color: #dc3545;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.logout-button:hover {
  background-color: #c82333;
}

/* User and Bot Specific Styles */
.message.user .message-content {
  align-items: flex-end;
  margin-right: 30px;
  width: calc(100% - 120px);
}

.message.bot .message-content {
  align-items: flex-start;
  margin-left: 30px;
  width: calc(100% - 120px);
}

/* User Message Bubble */
.user-bubble {
  display: inline-block;
  max-width: 50%;
  padding: 10px 15px;
  background-color: #e0e0e0; /* Light gray background */
  color: #000; /* Text color */
  border-radius: 20px;
  width: fit-content;
  word-wrap: break-word;
}

/* Bot Message Content */
.markdown-content {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

/* Prevent bot messages from having a bubble */
.message.bot .markdown-content {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
}

/* Ensure all tables within markdown are styled correctly */
.markdown-content table {
  border-collapse: collapse;
  width: 100%;
  margin: 1em 0;
  font-size: 0.9em;
  border: 1px solid #ddd;
}

.markdown-content table thead tr {
  background-color: #f5f5f5;
  text-align: left;
}

.markdown-content table th,
.markdown-content table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.markdown-content table tbody tr {
  border-bottom: 1px solid #ddd;
}

.markdown-content table tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.markdown-content table tbody tr:last-of-type {
  border-bottom: 2px solid #f5f5f5;
}

/* For tables that are too wide */
.markdown-content {
  overflow-x: auto;
}

.send-button svg {
  width: 18px;
  height: 18px;
  fill: #007bff; /* Change color as needed */
  transition: transform 0.2s;
}

.send-button:hover svg {
  transform: scale(1.1);
}

/* Bot bubble styling */
.bot-bubble {
  background-color: #f0f0f0;
  color: #000;
  padding: 10px 15px;
  border-radius: 20px;
  width: fit-content;
  max-width: 100%;
  word-wrap: break-word;
}
