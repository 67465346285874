.header {
  padding: 6px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(to right, #533979, #747474);
  border-bottom: 1px solid #ddd;
  flex-shrink: 0;
}

.menu-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.menu-icon {
  height: 20px;
  width: 20px;
  color: white;
  pointer-events: none;
}

.menu-button {
  padding: 6px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-button:hover {
  transform: scale(1.1);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 4px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 120px;
  transform: translateX(0);
}

.dropdown-item {
  padding: 8px 16px;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  color: #333;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}
