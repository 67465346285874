.sidebar {
  width: 260px;
  height: 100%;
  background-color: #f5f5f5;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
}

.new-chat-button-container {
  padding: 10px;
}

.new-chat-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: transparent;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s;
}

.new-chat-button:hover {
  transform: scale(1.1);
}

.new-chat-button svg {
  width: 20px;
  height: 20px;
}

.new-chat-button span {
  font-size: 16px;
  color: #000000;
}

.chats-list {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.chat-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.chat-item:hover {
  background-color: #e9ecef;
}

.chat-item.active {
  background-color: #e2e6ea;
}

.chat-title {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.delete-chat-button {
  opacity: 0;
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s;
}

.delete-chat-button svg {
  width: 14px;
  height: 14px;
}

.chat-item:hover .delete-chat-button {
  opacity: 0.6;
}

.delete-chat-button:hover {
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.1);
}
