.login-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.login-header {
  padding: 10px;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.login-box {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.login-input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-button {
  padding: 10px;
  font-size: 16px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.login-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-bottom: 10px;
  text-align: center;
}
